<template>
<div class="card-box-full">
  <div class="card-box-header">
    Fingerprint Profile
  </div>

  <div class="form-group row" style="margin-top: 15px; padding: 5px">
    <div class="col-md-12">
      <div class="input-group">

        <input class="form-control" v-model="fingerprint" autocomplete="on" placeholder="Enter a fingerprint" :disabled="isSearching" @keyup.enter="search()">
        <div class="input-group-append" color="primary">
          <CButton @click="search()" color="primary" :disabled="isSearching || fingerprint.length == 0" style="width: 80px">
            <div v-if="isSearching" class="spinner-border spinner-border-sm text-light" role="status" style="">
              <span class="sr-only"></span>
            </div>
            <div v-else>
              Lookup
            </div>
          </CButton>
        </div>
      </div>

      <!-- ----------------------------------------------------------------- -->


      <!-- ----------------------------------------------------------------- -->
      <div v-if="errorMessage">
        <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
      </div>

      <!-- ----------------------------------------------------------------- -->
      <div v-else-if="profile" style="margin: 15px 0px -20px 0px">

        <div class="card-box" style="width: 100%; margin-top: 10px; min-height: 70px; background-color: #f7f7f7">

          <div class="card-box" style="min-height: 50px; width: 100%; margin-top: 5px">
            <table width="100%">
              <tr style="font-size: 13px; font-weight: 600">
                <td align="center">Messages</td>
                <td align="center">Blocked</td>
                <td align="center">Allowed</td>
                <td align="center">First Seen</td>
                <td align="center">Last Seen</td>
                <td align="center">Expires</td>
              </tr>
              <tr>
                <td align="center">
                  <span class="badge badge-pill badge-light">
                    {{ profile.total_messages.toLocaleString() }}
                  </span>
                </td>
                <td align="center">
                  <span v-if="profile.total_blocked == 0" class="badge badge-pill badge-light">
                    {{ profile.total_blocked.toLocaleString() }}
                  </span>
                  <span v-else class="badge badge-pill badge-danger">
                    {{ profile.total_blocked.toLocaleString() }}
                  </span>
                </td>
                <td align="center">
                  <span v-if="profile.total_allowed == 0" class="badge badge-pill badge-light">
                    {{ profile.total_allowed.toLocaleString() }}
                  </span>
                  <span v-else class="badge badge-pill badge-success">
                    {{ profile.total_allowed.toLocaleString() }}
                  </span>
                </td>
                <td align="center">
                  <span class="badge badge-pill badge-light">
                    <timeago :datetime="profile.first_seen"></timeago>
                  </span>
                </td>
                <td align="center">
                  <span class="badge badge-pill badge-light">
                    <timeago :datetime="profile.last_seen"></timeago>
                  </span>
                </td>
                <td align="center">
                  <span class="badge badge-pill badge-light">
                    <timeago :datetime="profile.expires"></timeago>
                  </span>
                </td>
              </tr>
            </table>
          </div>

          <CTabs variant="tabs" style="margin-top: 5px; min-height: 135px">
            <CTab title="Last Message">
              <div class="tab-box" style="padding: 20px 10px 0px 0px; min-height: 80px">
                <table width="100%">
                  <tr>
                    <td width="90" valign="top" align="center">
                      <div>
                        <span class="badge badge-pill badge-light">{{ reasonToString(profile.last_reason) }}</span>
                        <div style="margin-top: 7px">
                          <CIcon name="cil-fingerprint" style="width: 20px; height: 20px" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-if="profile.last_message_blocked" class="alert alert-danger" style="padding: 5px">
                        <span class="badge badge-pill badge-danger" style="cursor: pointer" @click="clickedSender(profile.last_sender)">
                          {{ profile.last_sender }}
                        </span>
                        <span style="font-size: 12px; ">
                          <span style="margin-left: 5px">filtered </span>
                          <timeago :datetime="profile.last_seen"></timeago>
                        </span>
                        <br>
                        {{ profile.last_message }}
                      </div>
                      <div v-else class="alert alert-success" style="padding: 5px">
                        <span class="badge badge-pill badge-success" style="cursor: pointer" @click="clickedSender(profile.last_sender)">
                          {{ profile.last_sender }}
                        </span>
                        <span style="font-size: 12px; ">
                          <span style="margin-left: 5px">filtered </span>
                          <timeago :datetime="profile.last_seen"></timeago>
                        </span>
                        <br>
                        {{ profile.last_message }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </CTab>
            <!-- ---- -->
            <CTab title="Obscured">
              <div class="tab-box" style="padding: 20px 10px 0px 0px; min-height: 80px">
                <table width="100%">
                  <tr>
                    <td width="90" valign="top" align="center">
                      <div>
                        <span class="badge badge-pill badge-light">{{ reasonToString(profile.last_reason) }}</span>
                        <div style="margin-top: 7px">
                          <CIcon name="cil-fingerprint" style="width: 20px; height: 20px" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-if="profile.last_message_blocked" class="alert alert-danger" style="padding: 5px">
                        <span class="badge badge-pill badge-danger" style="cursor: pointer" @click="clickedSender(profile.last_sender)">
                          {{ profile.last_sender }}
                        </span>
                        <span style="font-size: 12px; ">
                          <span style="margin-left: 5px">filtered </span>
                          <timeago :datetime="profile.last_seen"></timeago>
                        </span>
                        <br>
                        {{ profile.obscured }}
                      </div>
                      <div v-else class="alert alert-success" style="padding: 5px">
                        <span class="badge badge-pill badge-success" style="cursor: pointer" @click="clickedSender(profile.last_sender)">
                          {{ profile.last_sender }}
                        </span>
                        <span style="font-size: 12px; ">
                          <span style="margin-left: 5px">filtered </span>
                          <timeago :datetime="profile.last_seen"></timeago>
                        </span>
                        <br>
                        {{ profile.obscured }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </CTab>
            <!-- --- -->
            <CTab title="Anonymized">
              <div class="tab-box" style="padding: 20px 10px 0px 0px; min-height: 80px">
                <table width="100%">
                  <tr>
                    <td width="90" valign="top" align="center">
                      <div>
                        <span class="badge badge-pill badge-light">{{ reasonToString(profile.last_reason) }}</span>
                        <div style="margin-top: 7px">
                          <CIcon name="cil-fingerprint" style="width: 20px; height: 20px" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-if="profile.last_message_blocked" class="alert alert-danger" style="padding: 5px">
                        <span class="badge badge-pill badge-danger" style="cursor: pointer" @click="clickedSender(profile.last_sender)">
                          {{ profile.last_sender }}
                        </span>
                        <span style="font-size: 12px; ">
                          <span style="margin-left: 5px">filtered </span>
                          <timeago :datetime="profile.last_seen"></timeago>
                        </span>
                        <br>
                        {{ profile.anonymized }}
                      </div>
                      <div v-else class="alert alert-success" style="padding: 5px">
                        <span class="badge badge-pill badge-success" style="cursor: pointer" @click="clickedSender(profile.last_sender)">
                          {{ profile.last_sender }}
                        </span>
                        <span style="font-size: 12px; ">
                          <span style="margin-left: 5px">filtered </span>
                          <timeago :datetime="profile.last_seen"></timeago>
                        </span>
                        <br>
                        {{ profile.anonymized }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </CTab>
          </CTabs>

          <div class="card-box" style="min-height: 50px; width: 100%; margin-top: 15px; margin-bottom: 5px">

            <table width="100%">
              <tr>
                <td>
                  <div style="font-size: 17px; font-weight: 600">Recent senders of this message</div>
                </td>
                <td align="right" style="padding-right: 15px; font-size: 13px">
                  <span v-if="sort != 'last_seen'" class="interval-link" @click="clickedSort('last_seen')">Last Seen</span>
                  <span v-if="sort == 'last_seen'" style="font-weight: bold">Last Seen</span>
                  |
                  <span v-if="sort != 'times_seen'" class="interval-link" @click="clickedSort('times_seen')">Times Seen</span>
                  <span v-if="sort == 'times_seen'" style="font-weight: bold">Times Seen</span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr style="margin-top: 5px">
                  <div v-if="isLoadingSenders" style="text-align: center">
                    <span class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 5px">
                      <span class="sr-only"></span>
                    </span>
                    <span style="padding-left: 7px"> Loading recent senders...</span>
                  </div>
                  <div v-else-if="isLoadingSenderProfiles" style="text-align: center">
                    <span class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 5px">
                      <span class="sr-only"></span>
                    </span>
                    <span style="padding-left: 7px"> Loading sender profiles...</span>
                  </div>
                  <div v-else-if="errorMessageSenders">
                    <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessageSenders }}</div>
                  </div>
                  <div v-else-if="errorSenderProfiles">
                    <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorSenderProfiles }}</div>
                  </div>
                  <div v-else>
                    <!-- ---------------------------------------- -->
                    <table width="100%">
                      <tr v-for="sender in senders">
                        <td>
                          <div>

                            <div v-if="senderProfiles[sender.sender].last_message_blocked" class="alert alert-danger" style="padding: 5px">
                              <span class="badge badge-pill badge-danger" style="cursor: pointer" @click="clickedSender(sender.sender)">
                                {{ sender.sender }}
                              </span>
                              <span style="font-size: 12px; ">

                                <span v-if="sort == 'last_seen'" style="margin-left: 3px">
                                  last seen <timeago :datetime="senderProfiles[sender.sender].last_seen"></timeago>
                                </span>
                                <span v-else style="margin-left: 5px">seen <b>{{ sender.times_seen.toLocaleString() }}</b> times</span>
                              </span>
                              <br>
                              <div v-if="showSenderMessages">
                                <div v-if="senderProfiles[sender.sender].last_fingerprint" style="cursor: pointer" @click="clickedFingerprint(senderProfiles[sender.sender].last_fingerprint)">
                                  {{ truncateMessage(senderProfiles[sender.sender].last_message) }}
                                </div>
                                <div v-else>
                                  {{ truncateMessage(senderProfiles[sender.sender].last_message) }}
                                </div>
                              </div>
                              <div v-else>
                                <div class="clickable-text" style="color: #3A9CFC" @click="showSenderMessages = true">Show the most recently seen message from this sender</div>
                              </div>
                            </div>
                            <div v-else class="alert alert-success" style="padding: 5px">
                              <span class="badge badge-pill badge-success" style="cursor: pointer" @click="clickedSender(sender.sender)">
                                {{ sender.sender }}
                              </span>
                              <span style="font-size: 12px; ">
                                <span v-if="sort == 'last_seen'" style="margin-left: 3px">
                                  last seen <timeago :datetime="senderProfiles[sender.sender].last_seen"></timeago>
                                </span>
                                <span v-else style="margin-left: 5px">seen <b>{{ sender.times_seen.toLocaleString() }}</b> times</span>
                              </span>
                              <br>
                              <div v-if="showSenderMessages">
                                <div v-if="senderProfiles[sender.sender].last_fingerprint" style="cursor: pointer" @click="clickedFingerprint(senderProfiles[sender.sender].last_fingerprint)">
                                  {{ truncateMessage(senderProfiles[sender.sender].last_message) }}
                                </div>
                                <div v-else>
                                  {{ truncateMessage(senderProfiles[sender.sender].last_message) }}
                                </div>
                              </div>
                              <div v-else>
                                <div class="clickable-text" style="color: #3A9CFC" @click="showSenderMessages = true">Show the most recently seen message from this sender</div>
                              </div>
                            </div>

                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>


</div>
</template>

<style>
.ellipsis-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
}
</style>

<script>
export default {
  name: 'Fingerprint-Lookup',
  data() {
    return {
      sort: "last_seen",
      errorMessage: null,
      errorMessageSenders: null,
      errorSenderProfiles: null,
      fingerprint: "",
      isLoadingSenderProfiles: false,
      isLoadingSenders: false,
      isSearching: false,
      profile: null,
      senders: null,
      senderProfiles: {},
      showSenderMessages: false
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedSort(sort) {
      if (this.isLoadingSenders) {
        return;
      }
      this.sort = sort;
      this.loadRecentSenders();
    },
    //--------------------------------------------------------------------------
    clickedFingerprint(fingerprint) {
      if (fingerprint == this.fingerprint) {
        this.scrollToSmoothly(0, 300);
        return;
      }
      this.$router.push({
        path: '/sms-profile/fingerprint/' + encodeURIComponent(fingerprint)
      });
    },
    //--------------------------------------------------------------------------
    clickedSender(sender) {
      this.$router.push({
        path: '/sms-profile/sender/' + encodeURIComponent(sender)
      });
    },
    //--------------------------------------------------------------------------
    downloadSenderProfiles() {
      if (this.isLoadingSenderProfiles) {
        return;
      } else {
        this.senderProfiles = {};
        this.errorSenderProfiles = null;
        this.isLoadingSenderProfiles = true;
      }

      var body = {
        senders: []
      };
      for (var x = 0; x < this.senders.length; x++) {
        body.senders.push(this.senders[x].sender);
      }
      body = JSON.stringify(body);

      var that = this;
      var method = "POST";
      var url = "https://sms-profile.platform.robocalls.ai/sender/profiles";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isLoadingSenderProfiles = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorSenderProfiles = message;
            } else {
              that.errorSenderProfiles = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponseSenderProfiles(result);
            } catch (err) {
              that.errorSenderProfiles = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isLoadingSenderProfiles = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isLoadingSenderProfiles = false;
          that.errorSenderProfiles = error;
        }
      })

    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      if (!response || !response.profile) {
        this.errorMessage = "Server did not return profile data";
        return;
      }
      this.profile = response.profile;
      this.loadRecentSenders();
    },
    //--------------------------------------------------------------------------
    processResponseSenders(response) {
      if (!response || !response.senders) {
        this.errorMessageSenders = "Server did not return senders";
        return;
      }
      this.senders = response.senders;
      this.downloadSenderProfiles();
    },
    //--------------------------------------------------------------------------
    processResponseSenderProfiles(response) {
      if (!response || !response.profiles) {
        this.errorSenderProfiles = "Server did not return profiles";
        return;
      }
      this.senderProfiles = {};
      for (var x = 0; x < response.profiles.length; x++) {
        this.senderProfiles[response.profiles[x].sender] = response.profiles[x];
      }
    },
    //--------------------------------------------------------------------------
    reasonToString(reason) {
      switch (reason.toLowerCase()) {
        case "domain_reputation":
          return "Domain";
        case "fingerprint_override":
          return "Fingerprint";
        case "sender_override":
          return "Sender";
        case "ml_model":
          return "ML Model";
        default:
          return reason;
      }
    },
    //--------------------------------------------------------------------------
    scrollToSmoothly(pos, time) {
      var currentPos = window.pageYOffset;
      var start = null;
      if (time == null) time = 500;
      pos = +pos, time = +time;
      window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
          window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
        } else {
          window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
        }
        if (progress < time) {
          window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, pos);
        }
      });
    },
    //--------------------------------------------------------------------------
    truncateMessage(message) {
      if (message.length > 95) {
        message = message.substring(0, 95) + "...";
      }
      return message;
    },
    //--------------------------------------------------------------------------
    loadRecentSenders() {
      if (this.isLoadingSenders) {
        return;
      } else {
        this.senders = null;
        this.errorMessageSenders = null;
        this.isLoadingSenders = true;
      }

      var that = this;
      var method = "GET";
      var url = "https://sms-profile.platform.robocalls.ai/fingerprint/senders?fingerprint=" + encodeURIComponent(this.fingerprint) + "&sort=" + encodeURIComponent(this.sort);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isLoadingSenders = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessageSenders = message;
            } else {
              that.errorMessageSenders = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponseSenders(result);
            } catch (err) {
              that.errorMessageSenders = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isLoadingSenders = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isLoadingSenders = false;
          that.errorMessageSenders = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    search() {

      // Make sure we have a fingerprint
      if (!this.fingerprint) {
        return;
      }

      // Make sure we're not currently searching
      if (this.isSearching) {
        return;
      } else {
        this.profile = null;
        this.isSearching = true;
        this.isLoadingSenders = false;
        this.errorMessage = null;
      }

      var that = this;
      var method = "GET";
      var url = "https://sms-profile.platform.robocalls.ai/fingerprint/profile?fingerprint=" + encodeURIComponent(this.fingerprint);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isSearching = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isSearching = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isSearching = false;
          that.errorMessage = error;
        }
      })

    },
  },
  mounted() {
    if (this.$route.params.fingerprint) {
      this.fingerprint = this.$route.params.fingerprint;
      this.search();
    }
  }
}
</script>
